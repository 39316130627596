<template>
  <div class="columns has-background-white is-centered">
    <div class="column is-half">    
      <h1 class="title is-size-5">Please set a new password</h1>
      <form @submit.prevent="onSubmit">
        <BaseInput
          label="New Password" 
          type="password"
          v-model="$v.user.password.$model"
          :validation="$v.user.password"
        />
        <BaseSubmit
          label="Reset password"
          :error="error"
          :disabled="$v.$invalid"
          :submitting="submitting"
        />
      </form>
    </div>

  </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  props: {
    token: {
      type: String,
      required: true
    },
  },
  components: {
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      submitting: false,
      error: null,
      user: {
        password: '',
      }
    }
  },
  validations: {
     user: {
      password: { required, minLength: minLength(8) },
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let params = {
        token: this.token,
        password: this.user.password
      }

      this.$store.dispatch("resetPasswordAndlogin", params)
        .then(() => {
          this.submitting = false
          this.$router.push("/");
        })
        .catch(error => {
          this.submitting = false
          this.error = error
        })
    
    }
  },
}
</script>
