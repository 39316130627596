<template>
  <div class="field">
    <label class="label">{{label}}</label>
    <div
      :class="showIconsClass()"
    >
      <input
        v-if="hasMask()"
        :disabled="disabled"
        :name="name"
        :value="value"
        :type="type"
        :class="inputClass()"
        @input="$emit('input', $event.target.value)"
        v-mask="mask"
      >
      <input
        v-else
        :disabled="disabled"
        :name="name"
        :value="value"
        :type="type"
        :class="inputClass()"
        @input="$emit('input', $event.target.value)"
      >
      <span
        v-if="type != 'text'"
        class="icon is-small is-left">
        <i :class="iconClass()"></i>
      </span>
      <span
        v-if="error"
        class="icon is-small is-right"
      >
        <i class="fas fa-exclamation-triangle"></i>
      </span>
    </div>
    <p
      v-if="error"
      v-html="formatErrorMsg()"
      class="help is-danger"
    >
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'email', 'password','number'].includes(value);
      }
    },
    name: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    mask: {
      type: String
    },
    validation: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    error: function() {
      if (this.validation && this.validation.$error) {
        return this.validation.$error
      } else {
        return false
      }
    }
  },
  methods: {
    hasMask() {
      if (this.mask == undefined || this.mask == '') {
        return false
      } else {
        return true
      }
    },
    formatErrorMsg() {
      if (this.error) {
        return `Invalid ${this.type}`
      } else {
        return ""
      }
    },
    inputClass() {
      if (!this.error) {
        return "input"
      } else {
        return "input is-danger"
      }
    },
    showIconsClass() {
      if (!this.error && this.type == 'text') {
        return "control"
      } else if (!this.error && this.type != 'text') {
        return "control has-icons-left"
      } else if (this.error && this.type == 'text') {
        return "control has-icons-right"
      } else {
        return "control has-icons-left has-icons-right"
      }
    },
    iconClass() {
      switch (this.type) {
        case 'email':
          return "fas fa-envelope"
        case 'password':
          return "fas fa-lock"
        case 'number':
          return "fas fa-dollar-sign"
        default:
          return ""
      }
    }
  }
};
</script>


