<template>
  <div class="field">
    <div class="control">
      <p 
        v-if="error != null"
        class="has-text-danger"
      >
        {{ error }}
      </p>
      
      <button
        :disabled="disabled" 
        type="submit"
        :class="showClass()"
      >
        {{label}}
      </button>
      <button
        v-if="cancelShow"
        class="button is-outline"
        style="margin-left:30px;"
        @click="go(cancelPath)"
      >
      Cancel
     </button>

     <!-- TODO - do we want to discard? -->
      <button
        v-if="clearShow && !disabled"
        class="button is-outline"
        style="margin-left:30px;"
        @click.prevent="reset()"
      >
       Discard changes
     </button>
     
    </div>
  </div> 
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      required: true
    },
    submitting: {
      type: Boolean,
      required: true
    },
    cancelShow: {
      type: Boolean
    },
    cancelPath: {
      type: String
    },
    clearShow: {
      type: Boolean
    },
    error: {
      type: String,
      required: false
    },
  },
  methods: {
    showClass() {
     if (this.submitting) {
        return "button is-link is-loading"
      } else {
        return "button is-link"
      }
    },
    go(path) {
      this.$router.push(path)
    },
    reset() {
      this.$parent.reset()
    },
  }
};
</script>


